import './cube.css';


function Cube(){
    return (
        <div className="cube">
            <div class="a"></div>
            <div class="b"></div>
            <div class="d"></div>
            <div class="e"></div>
            <div class="f"></div>
            <div class="g"></div>

        </div>
    )
}

export default Cube