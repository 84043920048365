import React, { useState, useEffect } from 'react';
import '../App.css';
import Header from '../header.js';

function Resume() {
return (
    <div className="App">
      <Header />
      <div className="about-me-container">
        <div class="left-column-resume">
            <img src="/Resums Screenshot April14.png" alt="My photo"></img>
        </div>
        <div class="middle-column">
            <h1>Resume Overview</h1>
            <p>I attend the Univeristy of Ottawa for Computer Science.</p>
            <p>Prior to my more relevent experiences I've worked in various customer service related jobs, where I developed communication, collaboration, and time-management skills.</p>
            <p>I am part of the co-op program at the University of Ottawa, and my first co-op oppurtunity was for BMO in the summer of 2023, where I worked as a data analyst. My back-end roles were to develop SAS and SQL scripts to produce correct weekly and yearly results on a scorecard for financial planner, teams, regions, and the nation. My front-end tasks included using services such as SSMS and SSRS, along with JavaScript, C++ and VisualBasic languages to create a viewable website where results can be seen each week by members of the workforce. There were some individual projects I had to work on such as developing an automated macro function which tests incoming tables for outliers and sends out alerts to members of the team, this was created with my knowledge of Probability and Statistics from second-year univeristy. We used github and tortoise to commit and collaborate our work as a team.</p>
            <p>In my second co-op term I worked as a web developer for Giatec Scientific. My work was mainly focused in the back-end where I used PHP to create various plugins that allow for easier and more flexible development of the website. My technical work extended to the front end as well using javascript, css, and HTML to design visually appealing web pages. Another aspect of the work as a web developer was analyzing and improving SEO, I would look into various web analytics softwares such as Google Analytics and SemRush, and would be tasked with creatively coming up with more optimal solutions to increase website outreach.</p>
            <p>I love working on software projects and I have also recently began working on my own startup with two of my classmates which is still in development. I also have begun to contribute work to SparkSites.io which is a new startup whom recently launched their website.</p>
            <p>The projects listed here are included under the "Projects info and Gallary" Section, if you wish to see and read about them view there, to see more tasks I've worked on please check out my GitHub.</p>
            
        </div>
        

      </div>
    </div>
  );
}

export default Resume;