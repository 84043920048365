import React, { useState, useEffect } from 'react';
import '../portfolio.css';
import Header from '../header.js';

function Portfolio() {
return (
    <body>
      <Header />
      

     
    

      <section class="project">
        <h2 class="project-title">Eccomerce website I made for my potential business</h2>
        <video controls autoplay muted loop src="Project 4(1).mp4" width="800"></video>
        
        <p class="project-description">
           This project is a full stack website and involves using MySQL as the back-end database and javascript, CSS, html to form the functionality and front-end. I use the react framework to build this website. I also have a server application that the website interacts with using express. The website is stil a work in progress as I am working on a secure way to handle payments and create automated requests to shipping. I am hoping to deploy and maintain this application using a cloud service, either Google Cloud or AWS.
        </p>
    </section>

    <section class ="project">
      <h2 class ="project-title">Chat App</h2>
      <img class="project-image" src="/chatApp.png" alt = "TutorBot Image"/>
      <img class="project-image" src="/chatApp1.png" alt = "TutorBot Image"/>
      <img class="project-image" src="/chatApp2.jpg" alt = "TutorBot Image"/>
      <p class="project-description">
           I wanted to explore making an app that involves chatting online. I made this app with my friend, it uses a node JS backend with the getstream.io API for chatting. This app includes chatting through texts and photos, activity status, reacting to messaging, and voice/video calling.
        </p>
    </section>
    <section class ="project">
      <h2 class ="project-title">Tutor Bot</h2>
      <img class="project-image" src="/TutorBotImg1.png" alt = "TutorBot Image"/>
      <img class="project-image" src="/TutorBotImg2.png" alt = "TutorBot Image"/>
      <p class="project-description">
           This project was made so I can get some experience with the Open AI API. I used the API to develop a chat bot that can confine to a domain of an uploaded pdf and then answer questions about it. I would use this to help study for some of my December 2023 exams.
        </p>
    </section>
     
    <section class="project">
        <h2 class="project-title">Android Mealer App</h2>
        <img class="project-image2" src="/Project 2(1).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(2).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(3).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(4).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(5).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(6).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(7).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(8).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(9).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(10).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(11).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(12).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(13).png" alt="Project 2 Image 1"/>
        <img class="project-image2" src="/Project 2(14).png" alt="Project 2 Image 1"/>
        <p class="project-description">
           This application was made using Android Studio and Java, with firebase as the database that stores user information. It was made as a version of uber eats but where the food sold is home-made food from home-made cooks. Features of the app can be seen in the photos, each have a desciption of the page under them.
        </p>
    </section>
    <section class="project">
        <h2 class="project-title">Dominant Plane Program (In 4 different paradigms)</h2>
        <video controls autoplay muted loop src="Project 3(1).mp4" width="500"></video>
        <img class="project-image" src="/Project 3(2).png" alt="Project 3 Image"/>
        <p class="project-description">
            This project was remade 5 times in 5 different paradigms, using the languages Java, GoLang, Scheme, Prolog and Python. This example right here is the Java (Object-oriented-programming paradigm) version shown with the UML diagram of the classes. This program identifies the three most dominant planes in a given xyz file of points. It also outputs 4 output files containing the points in each of the three dominant planes, as well as the original points file with all the points that were not in one of the three dominant planes.
        </p>
    </section>

    <section class="project">
        <h2 class="project-title">Gutters and Windows Website</h2>
        <img class="project-image" src="/Project 1(1).png" alt="Project 1 Image 1"/>
        <img class="project-image" src="/Project 1(2).png" alt="Project 1 Image 2"/>
        <img class="project-image" src="/Project 1(3).png" alt="Project 1 Image 3"/>
        <img class="project-image" src="/Project 1(4).png" alt="Project 1 Image 4"/>
        <img class="project-image" src="/Project 1(5).png" alt="Project 1 Image 5"/>
        <p class="project-description">
            This was a project made for a friend who was starting a window and gutter cleaning company in 2023. He wanted a quick website built to allow potentials customers to read about his buisiness. I built a website that fits those needs with multiple different pages in HTML, CSS and JavaScript. He was originally going to use resurva to schedule his appointments with customers but instead I built a backend database and allowed for customers to view and make appointments, notifying the business by email once that happens. That was made using NodeJS and MySQL.
        </p>
    </section>
    
    </body>
  );
}

export default Portfolio;